<template>
    <div v-loading="$wait.is('loading.clients_over_time')" class="card">
        <h2 class="mb-8">
            {{ $t('statistics.new_clients_over_time') }}
        </h2>

        <canvas ref="myChart" />
    </div>
</template>
<script>
import {
    Chart, LinearScale, BarElement, BarController, CategoryScale,
} from 'chart.js';

Chart.register(
    BarElement,
    BarController,
    CategoryScale,
    LinearScale,
);

export default {
    props: {
        dateRange: {
            type:    Object,
            default: () => {},
        },
    },

    data() {
        return {
            dataChart: {},
            myChart:   {},
        };
    },

    watch: {
        dateRange() {
            this.getAll();
        },
    },

    mounted() {
        this.myChart = new Chart(this.$refs.myChart, {
            type: 'bar',
            data: {
                labels:   this.dataChart.labels,
                datasets: [
                    {
                        label:           this.$t('statistics.clients'),
                        data:            this.dataChart.data,
                        backgroundColor: this.dataChart.colors,
                    },
                ],
            },
            options: {
                responsive:          true,
                maintainAspectRatio: false,
                legend:              {
                    display: false,
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                            },
                        },
                    ],
                },
            },
        });

        this.getAll();
    },

    methods: {
        async getAll() {
            this.$wait.start('loading.clients_over_time');
            try {
                const { data } = await axios.get(`${process.env.VUE_APP_CORE_API_URL}/statistics/new_clients_over_time`, {
                    params: {
                        from: this.dateRange.from,
                        to:   this.dateRange.to,
                    },
                });
                this.dataChart = Object.freeze(data);

                this.updateChart();

                this.$wait.end('loading.clients_over_time');
                this.$wait.end('loading_date_range.prev');
                this.$wait.end('loading_date_range.next');
            } catch (error) {
                this.$wait.end('loading.clients_over_time');
            }
        },

        updateChart() {
            this.myChart.data.labels = this.dataChart.labels;
            this.myChart.data.datasets.forEach(dataset => {
                dataset.data = this.dataChart.data;
                dataset.backgroundColor = this.dataChart.colors;
            });

            this.myChart.update();
        },
    },
};
</script>
<style scoped>
.card {
    height: 360px;
    padding-bottom: 70px;
}
</style>
